.between {
	justify-content: space-between;
}

.leftFloat {
	//background: #FFFFFF;
	margin-top: 18px;
	padding: 16px;
	width: 45.5%;
	border-radius: 8px;
	border: 1px solid #0067A6;

	.frequency {
		.text {
			width: 30%;
			text-align: center;
			color: #FFFFFF;
			font-size: 17px;
		}

		strong {
			margin-bottom: 5px;
			display: block;
		}

		span {
			font-size: 3em;
			font-weight: bold;
			color: #19D4AE;
		}
	}

	.echartscom {
		width: 50%;
	}
}

.tit {
	width: 46%;
	height: 40px;
	background: linear-gradient(90deg, #0067a6 0%, rgba(0, 103, 166, 0) 100%);
	line-height: 40px;
	padding-left: 15px;
	border-radius: 8px 0 0 0;
	font-size: 17px;
	font-weight: bold;
	color: #FFFFFF;
}

.exceedTab {
	//background: #FFFFFF;
	margin-top: 18px;
	padding: 16px;
	border-radius: 8px;
	border: 1px solid #0067A6;
}

.table ::v-deep {
	margin-top: 16px;
	text-align: center;

	.el-table__body-wrapper, .el-table__footer-wrapper, .el-table__header-wrapper {
		background: #212844;
	}

	.el-table__fixed-right::before, .el-table__fixed::before {
		display: none;
	}

	.el-table td {
		padding: 0;
		font-weight: 400;
		color: rgba(255, 255, 255, 0.5);
		height: 43px;
	}

	.el-table th, .el-table tr {
		background: #212844;
	}

	.el-table--enable-row-hover .el-table__body tr:hover > td,
	.el-table__body tr.hover-row.current-row > td,
	.el-table__body tr.hover-row.el-table__row--striped.current-row > td,
	.el-table__body tr.hover-row.el-table__row--striped > td,
	.el-table__body tr.hover-row > td {
		background: none;
	}

	.update {
		min-width: 64px;
		height: 32px;
		background: rgba(213, 13, 14, 0.05);
		font-weight: 400;
		color: #D50D0E;
		padding: 0 10px;
		border: none;
	}

	.pagination {
		padding: 10px 0;

		li {
			color: #666666;
			background: transparent;
		}

		.el-pagination__total {
			margin-left: 10px;
			color: #FFFFFF;
		}

		.btn-next, .btn-prev, li.active {
			color: #FFFFFF;
			background: transparent;
		}
	}
}

.echarts {
	width: 65%;
}

.overNightBtn {
	justify-content: space-between;

	.el-button {
		color: #FFFFFF;
		line-height: 32px;
		height: 32px;
		border: none;
		background: #0067A6;
		border-radius: 5px;
		padding: 0 16px;
	}

}
